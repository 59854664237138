import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { EventBus, HttpClient } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import LoadingView from "./LoadingView";
import { UNAUTHORIZED } from "../../events";
import { USER_ACCOUNTS_LOADED } from "store/actions";

const UserAccountsLoader = ({ children }) => {
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUserAccounts() {
      const response = await HttpClient.send(
        {
          url: `${dashboardContext.apiBaseUrl}/auth/accounts`,
        },
        { stopErrorDisptaching: true }
      );

      if (response.succeed) {
        const userAccounts = await response.json();

        if (!userAccounts.length) {
          EventBus.dispatch(UNAUTHORIZED);
          setIsLoading(false);
          return;
        }

        dispatch({ type: USER_ACCOUNTS_LOADED, userAccounts });
        setIsLoading(false);
      }
    }
    getUserAccounts();
  }, [dashboardContext.apiBaseUrl]);

  return <>{isLoading ? <LoadingView/> : children}</>;
};

export default UserAccountsLoader;
