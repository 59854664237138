import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HttpClient, EventBus } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import LoadingView from "./LoadingView";
import PersonIcon from "@mui/icons-material/Person";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CURRENT_USER_CONTEXT_CHANGED } from "store/actions";
import {
  CURRENT_SCOPE_CHANGED,
  CURRENT_USER_ACCOUNT_CHANGED,
} from "../../events";
import {
  CookieManager,
  COOKIENAME_CURRENTUSERACCOUNTKEY,
} from "components/common/CookieManager";

const UserContextSwitcher = ({ children }) => {
  const USER_CONTEXT_INFO_STORAGE_KEY = "gc.dashboard.usercontextinfo";
  const USER_COOKIE_EXPIRES_DAYS = 3;

  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation(["GcDashboard-UserContextSwitcher"]);

  const userAccounts = useSelector((state) => state.userAccounts.userAccounts);
  const currentUserContext = useSelector(
    (state) => state.userAccounts.currentUserContext
  );
  const [isInitializing, setIsInitializing] = useState(true);
  const [isAccountChanging, setIsAccountChanging] = useState(false);
  const isAccountChangingRef = useRef(isAccountChanging);

  const setRequestsParams = (userAccount, scope) => {
    // sets new account key cookie to be send with an every request to API
    CookieManager.setCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY,
      userAccount.accountKey,
      USER_COOKIE_EXPIRES_DAYS      
    );

    HttpClient.defaultHeaders["scope"] = scope || "";
  };

  const saveUserContextInfo = (userContext) => {
    CookieManager.setCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY,
      userContext.account.accountKey,
      USER_COOKIE_EXPIRES_DAYS
    );

    localStorage.setItem(
      USER_CONTEXT_INFO_STORAGE_KEY,
      JSON.stringify({
        scope: userContext.scope,
      })
    );
  };

  const readUserContextInfo = () => {
    const accountKey = CookieManager.getCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY
    );
    const localStorageData =
      JSON.parse(localStorage.getItem(USER_CONTEXT_INFO_STORAGE_KEY)) || {};

    return { accountKey: accountKey, scope: localStorageData.scope };
  };

  const notifyUserContextChanged = (userContext) => {
    saveUserContextInfo(userContext);
    dispatch({
      type: CURRENT_USER_CONTEXT_CHANGED,
      currentUserContext: userContext,
    });
  };

  const loadFeatures = async () => {
    const response = await HttpClient.send({
      url: `${dashboardContext.apiBaseUrl}/features`,
    });

    const features = await response.json();
    return features;
  };

  useEffect(() => {
    isAccountChangingRef.current = isAccountChanging;
  }, [isAccountChanging]);

  useEffect(() => {
    async function setInitialUserContext() {
      try {
        setIsInitializing(true);
        const userContext = { account: {}, scope: "", features: [] };

        const savedUserContextInfo = readUserContextInfo() || {};
        const currentUserAccount = userAccounts.find(
          (a) => a.accountKey === savedUserContextInfo.accountKey
        );

        if (currentUserAccount) {
          userContext.account = currentUserAccount;
          userContext.scope = currentUserAccount.scopes.find(
            (s) => s === savedUserContextInfo.scope
          );
        } else {
          userContext.account = userAccounts[0];
        }

        if (userContext.account) {
          setRequestsParams(userContext.account, userContext.scope);

          const features = await loadFeatures();
          userContext.features = features;
          notifyUserContextChanged(userContext);
        }
      } finally {
        setIsInitializing(false);
      }
    }
    setInitialUserContext();
  }, [userAccounts]);

  useEffect(() => {
    const currentUserAccountChangedHandler = async (account) => {
      let isAccountSelected = account ? true : false;
      if (!isAccountSelected || isAccountChangingRef.current === true) {
        return;
      }

      var timeout = setTimeout(() => {
        setIsAccountChanging(true);
      }, 1000);

      try {
        // resets scope due to user account changed
        const scope = "";
        setRequestsParams(account, scope);

        var features = await loadFeatures();

        const userContext = {
          account: account,
          scope: scope,
          features: features,
        };

        notifyUserContextChanged(userContext);
      } finally {
        clearTimeout(timeout);
        setIsAccountChanging(false);
      }
    };

    const currentScopeChangedHandler = (scope) => {
      const userContext = {
        account: currentUserContext.account,
        scope: scope,
        features: currentUserContext.features,
      };

      setRequestsParams(userContext.account, userContext.scope);
      notifyUserContextChanged(userContext);
    };

    const currentUserAccountChangedEvent = EventBus.on(
      CURRENT_USER_ACCOUNT_CHANGED,
      currentUserAccountChangedHandler
    );
    const currentScopeChangedEvent = EventBus.on(
      CURRENT_SCOPE_CHANGED,
      currentScopeChangedHandler
    );

    return () => {
      EventBus.remove(
        CURRENT_USER_ACCOUNT_CHANGED,
        currentUserAccountChangedEvent
      );
      EventBus.remove(CURRENT_SCOPE_CHANGED, currentScopeChangedEvent);
    };
  }, [currentUserContext]);

  const modalLoader = (
    <Dialog
      open={isAccountChanging}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <PersonIcon
            fontSize="large"
            sx={{ color: theme.palette.secondary.main }}
          ></PersonIcon>
          <Typography gutterBottom variant="h3" textAlign="center">
            {t("SwitchingUserContext", "Switching user context")}
          </Typography>
          <LinearProgress sx={{ width: "100%" }}></LinearProgress>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      {isInitializing ? (
        <LoadingView />
      ) : (
        <>
          {modalLoader}
          {children}
        </>
      )}
    </>
  );
};

export default UserContextSwitcher;
