// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector, useDispatch } from "react-redux";
import { EventBus } from "@gearcode/react-components/lib/Services";
import { useEffect } from "react";
import { UNMATCHED_SELECTED_MENU_ITEM } from "../../../../events";
import { MENU_OPEN } from "store/actions";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const currentUserContext = useSelector(
    (state) => state.userAccounts.currentUserContext
  );
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  const filterMenuItems = (item) => {
    const hasItemFeature = item.feature ? true : false;
    const isFeatureEnabled = currentUserContext.features.some(
      (p) => p.name === item.feature
    );

    if (hasItemFeature) {
      if (!isFeatureEnabled) {
        return null;
      }
    }

    if (item.requiredPermission) {
      if (
        currentUserContext.account.permissions.some(
          (p) => p === item.requiredPermission
        )
      ) {
        return item;
      }
      return null;
    }

    if (item.children) {
      const newItem = { ...item };
      newItem.children = item.children.map((child) => {
        return filterMenuItems(child);
      });
      if (newItem.children.some((child) => child !== null)) {
        newItem.children = newItem.children.filter((child) => child !== null);
        return newItem;
      }
      return null;
    }

    return item;
  };

  const visibleItems = menuItem.items
    .map((item) => filterMenuItems(item))
    .filter((item) => item !== null);

  // redirects to default page if current is not visible
  useEffect(() => {
    if (!currentUserContext.account.accountKey || customization.isOpen.length === 0) {
      return;
    }

    const hasMatchingId = visibleItems.some(
      (obj) =>
        obj.children.some((child) => customization.isOpen.includes(child.id)) ||
        // second level
        obj.children.some(
          (child1) =>
            child1.children &&
            child1.children.some((child2) =>
              customization.isOpen.includes(child2.id)
            )
        )
    );
    
    if (hasMatchingId === false) {
      EventBus.dispatch(UNMATCHED_SELECTED_MENU_ITEM);
    }
  });

  // active menu item on page load
  useEffect(() => {
    function flatten(node) {
      let result = [];
      if (node.url) {
        result = [...result, { id: node.id, url: node.url }];
      }

      if (node.children) {
        node.children.forEach((child) => {
          result = result.concat(flatten(child));
        });
      }

      return result;
    }

    const currentItem = menuItem.items
      .map((item) => flatten(item))
      .reduce((acc, val) => [...acc, ...val])
      .find((x) => document.location.pathname.includes(x.url));

    if (currentItem) {
      dispatch({ type: MENU_OPEN, id: currentItem.id });
    }
    // eslint-disable-next-line
  }, [document.location.pathname]);

  const navItems = visibleItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
