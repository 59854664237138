import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const Orders = Loadable(
    lazy(() => import("components/linker/Orders"))
);

const Applications = Loadable(
    lazy(() => import("components/linker/Orders/applications/Applications"))
);

const LinkerRoutes = {
    path: "/linker",
    element: <MainLayout />,
    children: [
        {
            path: "/linker/orders",
            element: <Orders />,
        },
        {
            path: "/linker/applications",
            element: <Applications />,
        },
    ],
};

export default LinkerRoutes;
