// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - user accounts reducer
export const USER_ACCOUNTS_LOADED = '@userAccounts/USER_ACCOUNTS_LOADED';
export const CURRENT_USER_CONTEXT_CHANGED = '@userAccounts/CURRENT_USER_CONTEXT_CHANGED';

export const CUSTOM_TENANT_ENABLED_CHANGED = '@customTenant/CUSTOM_TENANT_ENABLED_CHANGED';
export const SET_CUSTOM_TENANT = '@customTenant/SET_CUSTOM_TENANT';