import * as actionTypes from "./actions";

export const initialState = {
  userAccounts: [],
  currentUserContext: { account: {}, scope: "", features: [] },
};

const userAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_ACCOUNTS_LOADED:
      return {
        ...state,
        userAccounts: action.userAccounts,
      };

    case actionTypes.CURRENT_USER_CONTEXT_CHANGED:
      return {
        ...state,
        currentUserContext: action.currentUserContext,
      };

    default:
      return state;
  }
};

export default userAccountsReducer;
