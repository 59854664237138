// assets
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const general = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/default',
            icon: DashboardOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: "calendar-events",
            title: "Calendar",
            type: "item",
            url: "/calendar-events",
            icon: CalendarMonthIcon,
            breadcrumbs: false,
            requiredPermission: "CALENDAR",
            feature: "CALENDAR"
        },        
        {
            id: "scoring-profile",
            title: "Scoring",
            type: "item",
            url: "/scoring-profile",
            icon: GradeOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: "AZURE_SEARCH_SCORING_PROFILE_WRITE",
            feature: "SCORING_PROFILE"
        },
        {
            id: "configuration",
            title: "Configuration",
            type: "item",
            url: "/configuration",
            icon: SettingsIcon,
            breadcrumbs: false,
            requiredPermission: "CONFIGURATION",
            feature: "CONFIGURATION"
        },        
    ]
};

export default general;
