// assets
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import permissions from 'permissions';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const messaging = {
    id: 'messaging',
    title: 'GC.Messaging',
    type: 'group',
    children: [
        {
            id: "messages",
            title: "Messages",
            type: "collapse",
            icon: EmailOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.MESSAGING_MESSAGES,
            feature: "MESSAGING_MESSAGES",
            children: [
                {
                    id: "send-message",
                    title: "Send Message",
                    type: "item",
                    url: "/messaging/messages/send",
                    icon: SendOutlinedIcon,
                    breadcrumbs: false,
                },
                {
                    id: "outbox",
                    title: "Outbox",
                    type: "item",
                    url: "/messaging/messages/outbox",
                    icon: OutboxOutlinedIcon,
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: "contacts",
            title: "Contacts",
            type: "item",
            url: "/messaging/contacts",
            icon: ContactsOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.MESSAGING_CHATS,
            feature: "MESSAGING_CHATS",
        }
    ]
};

export default messaging;
