import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const AzureSearchLoaderLogs = Loadable(
  lazy(() => import("components/asl/index"))
);

const FeedLogs = Loadable(lazy(() => import("components/feed/index")));

const ImportDataLogs = Loadable(
  lazy(() => import("components/dataSyncLogs/DataLogs"))
);

const ImportADFLogs = Loadable(
  lazy(() => import("components/dataSyncLogs/DataFactoryLogs"))
);

const OtherRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/asl",
      element: <AzureSearchLoaderLogs />,
    },
    {
      path: "/feed",
      element: <FeedLogs />,
    },
    {
      path: "/datasync/data",
      element: <ImportDataLogs />,
    },
    {
      path: "/datasync/datafactory",
      element: <ImportADFLogs />,
    },
  ],
};

export default OtherRoutes;
