import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { Navigate } from "react-router";

const DashboardDefault = Loadable(
  lazy(() => import("components/dashboard/Default"))
);

const CalendarEvents = Loadable(
  lazy(() => import("components/calendar/index"))
);

const ScoringProfile = Loadable(
  lazy(() => import("components/scoringProfile/index"))
);

const Configuration = Loadable(
  lazy(() => import("components/default/Configuration"))
);

const GeneralRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="default" />,
    },
    {
      path: "/default",
      element: <DashboardDefault />,
    },
    {
      path: "/calendar-events",
      element: <CalendarEvents />,
    },
    {
      path: "/scoring-profile",
      element: <ScoringProfile />,
    },
    {
      path: "/configuration",
      element: <Configuration />,
    },
  ],
};

export default GeneralRoutes;
