// assets
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import permissions from 'permissions';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const linker = {
    id: "linker",
    title: "GC.Linker",
    type: "group",
    feature: "GCLINKER",
    children: [
        {
            id: "applications",
            title: "Applications",
            type: "item",
            url: "/linker/applications",
            icon: DisplaySettingsIcon,
            breadcrumbs: false,
            requiredPermission: permissions.LINKER
        },
        {
            id: "orders",
            title: "Orders",
            type: "item",
            url: "/linker/orders",
            icon: LocalShippingOutlinedIcon,
            breadcrumbs: false,
            requiredPermission: permissions.LINKER
        },
    ],
};

export default linker;
