import general from "./general";
import diagnostic from "./diagnostic";
import automation from "./automation";
import monitoring from "./monitoring";
import linker from "./linker";
import messaging from "./messaging";

const menuItems = {
  items: [general, monitoring, messaging, linker, automation, diagnostic],
};

export default menuItems;
