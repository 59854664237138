import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { LinearProgress, Typography } from "@mui/material";
import LogoView from "./LogoView";

const LoadingView = () => {
  const theme = useTheme();
  const { t } = useTranslation(["GcDashboard-LoadingView"]);
  return (
    <LogoView>
      <Typography
        color={theme.palette.secondary.main}
        gutterBottom
        variant="h3"
        textAlign="center"
      >
        {t("Loading", "Loading")}...
      </Typography>
      <LinearProgress sx={{ width: "100%" }}></LinearProgress>
    </LogoView>
  );
};

export default LoadingView;
