import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import GeneralRoutes from "./GeneralRoutes";
import AutomationRoutes from "./AutomationRoutes";
import DiagnosticRoutes from "./DiagnosticRoutes";
import MessagingRoutes from "./MessagingRoutes";
import LinkerRoutes from "./LinkerRoutes";
import MonitoringRoutes from "./MonitoringRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    AutomationRoutes,
    DiagnosticRoutes,
    GeneralRoutes,
    MessagingRoutes,
    LinkerRoutes,
    MonitoringRoutes
  ]);
}
